
/* ====================== Section Heading ==================== */
.section-heading {
  text-align: center;
  margin-bottom: clampCal(32, 48);
  margin-left: auto;
  margin-right: auto;
  &__title {
    position: relative;
    margin-bottom: 16px;
    text-transform: capitalize;
  }
  &__desc {
    margin-top: clampCal(16, 32);
    font-size: clampCal(16, 18);
    max-width: 634px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    &.w-sm {
      max-width: 465px;
    }
  }

  // Style left
  &.style-left {
    text-align: left;
    margin-left: 0;
    .section-heading {
      &__desc {
        margin-left: 0;
      }
    }
  }
  // Style White
  &.style-white {
    .section-heading {
      &__title {
        color: hsl(var(--static-white));
      }
      &__desc {
        color: #bdbecc;
        font-weight: 300;
      } 
    }
  }
  // Style Flex
  &.style-flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: unset;
    align-items: center;
    .section-heading {
      &__inner {
        max-width: 410px;
        &.w-lg {
          max-width: 636px;
        }
      }
    }
  }
}
.color-title-new {
  color: #008BD2 !important;
}

.btn-new {
  background-color: #008BD2 !important; /* Blue background */
  color: yellow !important; /* Yellow text color */
  padding: 12px 12px; /* Increased padding */
  font-size: 16px !important; /* Larger font size */
  border-radius: 50px !important; /* Pill shape */
  border: none !important; /* Remove default border */
  text-decoration: none !important; /* Remove underline */
  display: flex; /* Use flexbox for alignment */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */ /* Full width */
  transition: background-color 0.3s ease !important; /* Smooth transition for hover effects */
}

.btn-new:hover {
  background-color: #0077B6 !important; /* Darker blue on hover */
}

.btn-new:focus {
  background-color: #0077B6 !important; /* Darker blue on focus */
  outline: none !important; /* Remove default focus outline */
}

.btn-new a {
  color: yellow !important; /* Ensure link text is yellow */
  text-decoration: none !important; /* Remove underline from link */
  width: 100%; /* Make link fill the button */
  height: 100%; /* Make link fill the button */
  display: flex !important; /* Use flexbox for alignment */
  justify-content: center !important; /* Center link text horizontally */
  align-items: center !important; /* Center link text vertically */
}
/* ====================== Section Heading En d==================== */
