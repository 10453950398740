/* =========================== Product Css Start ========================== */
.product-item {
    border-radius: 8px;
    background-color: hsl(var(--white));
    overflow: hidden;
    transition: .2s linear;
    height:100%;
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    @media (min-width: 424px) and (max-width: 575px) {
        display: flex;
    }
    &.overlay-none {
        .product-item__thumb {
            &::before {
                display: none;
            }
        }
    }
    &:hover {
        box-shadow: 0px 20px 30px 0px rgba(197, 196, 201, 0.25);
        .product-item {
            &__thumb {
                &::before {
                    height: 100%;
                    visibility: visible;
                    opacity: 1;
                    z-index: 1;
                }
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
    &__thumb {
        margin: 8px;
        border-radius: inherit;
        overflow: hidden;
        padding-bottom: 0;
        position: relative;
        
        // height:70%;
        // width: 100%;
        // @media (min-width: 575px) {
        //     max-height: 156px;
        // }
        flex-shrink: 0;
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 0%;
            left: 0;
            bottom: 0;
            border-radius: 8px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 4, 37, 0.50) 69.5%, rgba(0, 4, 37, 0.50) 100%);
            pointer-events: none;
            visibility: hidden;
            opacity: 0;
            transition: .2s linear;
        }
        img {
            transition: .2s linear;
            height: 100%;
            width: 100%;
        }
    }
    &__wishlist {
        position: absolute;
        right: 8px;
        top: 8px;
        width: 40px;
        height: 40px;
        border-radius: 30px;
        background-color: hsl(var(--white)/.5);
        backdrop-filter: blur(5px);
        color: hsl(var(--black));
        z-index: 2;
        transition: .2s linear;
        &:active {
            transform: scale(1.15);
        }
        &.style-two {
            position: relative;
            top: 0;
            right: 0;
            background-color: hsl(var(--section-bg));
            width: 32px;
            height: 32px;
            @include font-12; 
            &:hover {
                background-color: hsl(var(--black));
                color: hsl(var(--white));
            }
        }
        &:hover {
            background-color: hsl(var(--white));
        }
        &.active {
            background-color: hsl(var(--black));
            color: hsl(var(--white));
        }
    }
    &__content {
        padding: 24px 16px;
        padding-top: 16px;
        margin-top: auto;
        // flex-grow:1;
    }
    &__title {
        margin-bottom: 8px;
        text-transform: capitalize; 
        font-weight: 600;
        .link {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }
    }
    &__info {

    }
    &__author {
        @include font-14; 
        font-weight: 400;
        color: hsl(var(--black-three));
        .link {
            color: inherit; 
        }
    }
    &__price {

    }
    &__prevPrice {
        @include font-14; 
        font-weight: 400;
        color: hsl(var(--gray-three));
    }
    &__bottom {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid hsl(var(--gray-five));
        @include sm-screen {
            margin-top: 8px;
            padding-top: 8px;
        }
    }
    &__sales {

    }
}

.download-icon {
    &:hover {
        .icon {
            .dark-version {
                display: block !important;
            }
            .white-version {
                display: none !important;
            }
        }
    }
}
.product-image-container {
    width: 100%; /* Full width of the column */
    position: relative;
    overflow: hidden; /* Hide overflowing parts of the image */
    background-color: #f0f0f0; /* Optional fallback background */
    max-height: 200px;
}
  
  /* Image cropping and centering */
  .product-image {
    width: 100%;
    height: 100%;
    object-fit: fit; /* Crop image to fill the square */
    object-position: center; /* Center the image */
    border-radius: 8px; /* Optional: rounded corners */
  }
/* Author Css Start */
.author-info {
    &__thumb {
        width: 114px;
        height: 114px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: hsl(var(--white));
        position: relative;
        z-index: 1;
        &::before {
            position: absolute;
            content: "";
            width: 80px;
            height: 80px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: hsl(var(--section-bg));
            z-index: -1;
            border-radius: inherit;
        }
    }
}

.line-separator {
    height: '1px';
    background-color: '#ccc';
    margin: '20px 0';
}
/* Mobile slider styling */
.slick-slide {
    padding: 0 5px;
}

.slick-list {
    margin: 0 -5px;
}

/* Mobile-first slider sizing */
.slick-slide img {
    width: 100%;
    height: auto;
    max-width: 100vw;
}

/* Hide dots on mobile */
@media (max-width: 767px) {
    .slick-dots {
        display: none !important;
    }
}

/* Touch/swipe improvements */
.slick-slider {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.slick-track {
    display: flex;
    align-items: center;
}
/* Author Css End */
/* =========================== Product Css End ========================== */