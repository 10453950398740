/* ================================= Form Css Start =========================== */
/* input Start */
.common-input {
    border-radius: 5px;
    font-weight: 400;
    outline: none;
    width: 100%;
    padding: 17px 16px;
    background-color: transparent !important; 
    border: 1px solid hsl(var(--border-color));
    color: hsl(var(--black)); 
    line-height: 1;
    @include font-17; 
    @include md-screen {
        padding: 10px 16px;
    }
    &::placeholder {
        color: hsl(var(--black-three));
        @include font-14; 
        transition: .25s linear;
        font-weight: 400;
    }
    &--md {
        padding: 13px 16px;
        @include font-15; 
    }
    &--lg {
        padding: 25px 24px;
        @include sm-screen {
            padding: 18px 24px;
        }
    } 
    &:focus {
        border-color: hsl(var(--main)); 
        box-shadow: none;
        &::placeholder {
            visibility: hidden;
            opacity: 0;
        }
    }
    &:disabled, &[readonly] {
        background-color: hsl(var(--black)/.2); 
        opacity: 1;
        border: 0;
    }
    &[type="password"] {
        color: hsl(var(--black)/.5);
        &:focus {
            color: hsl(var(--black)); 
        }
    }
}
.common-input {
    &--withIcon {
        padding-right: 50px !important;
    }
    &--withLeftIcon {
        padding-left: 50px !important;
    }
    &--bg {
        background-color: hsl(var(--gray-seven)) !important;
        border-color: hsl(var(--gray-seven)) !important;
        &::placeholder {
            color: hsl(var(--black-three));
        }
    }
}
/* input End */

/* input icon */
.input-icon {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    color: hsl(var(--heading-color));
    @include font-14; 
    &--left {
        left: 20px;
        right: auto;
    }
}
textarea+.input-icon {
    top: 15px;
    transform: translateY(0);
}
/* input icon */

/* Label */
.form-label {
    margin-bottom: 6px;
    @include font-14; 
    color: hsl(var(--heading-color));
    font-weight: 500;
    
}

/* Form Select */  
select option {
    background-color: hsl(var(--white));
}
.select-has-icon {
    position: relative;
    &::before {
        position: absolute;
        content: "\f107";
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        @include font-12; 
        color: hsl(var(--main)); 
        transition: 0.2s linear;
        pointer-events: none;
        color: hsl(var(--body-color)) !important;
    }
    &.icon-black::before {
        -webkit-text-fill-color: hsl(var(--black)); 
    }
    select {
        background-image: none;
        -webkit-appearance: none;
        padding: 19px 24px;
        @include font-16; 
        @include md-screen {
            padding: 12px 24px;
        }
    }
    .common-input {
        padding-right: 30px !important;
    }
} 
.select {
    color: hsl(var(--black) / .6) !important;
    padding: 16px 24px;
    background-color: transparent;
    @include md-screen {
        padding: 11px 24px;
    }
    @include sm-screen {
        padding: 9px 24px;
        @include font-15; 
    }
    &:focus {
        border-color: hsl(var(--main));
        color: hsl(var(--black)) !important;
        outline: none;
    }
    option {
        background-color: hsl(var(--white));
        color: hsl(var(--heading-color));
    }
}
/* Form Select End */

// Message Field Css
textarea {
    &.common-input {
        height: 140px;
        @include sm-screen {
            height: 110px;
        }
    }
}
/* Autofill Css */
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active, 
textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, textarea:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill, textarea:-webkit-autofill, textarea:-webkit-autofill, textarea:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-text-fill-color: hsl(var(--heading-color)) !important;
    caret-color: hsl(var(--heading-color));
}
/* Autofill Css End */

/* Show Hide Password */
input#your-password, input#confirm-password {
    padding-right: 50px;
}
.password-show-hide {
    position: absolute;
    right: 20px;
    z-index: 5;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    color: hsl(var(--black)/.4);
}

/* Number Arrow None */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type=number]{
    -moz-appearance: textfield;
}

/* Custom Checkbox & Radio Css Start */
.common-check {
    @extend .flx-align; 
    margin-bottom: 16px;
    &:last-child {
        margin-bottom: 0;
    }
    a {
        display: inline;
    }

    &.common-radio {
        .form-check-input {
            border-radius: 50%;
            &:checked { 
                background-color: transparent !important;
                &::after {
                    visibility: visible;
                    opacity: 1;
                    -webkit-transform: translate(-50%, -50%) scale(1);
                    transform: translate(-50%, -50%) scale(1);
                }
            }
        }
    }

    .form-check-input {
        transition: .2s linear;
        box-shadow: none;
        background-color: transparent;
        box-shadow: none !important;
        border: 0;
        position: relative;
        border-radius: 3px;
        width: 18px;
        height: 18px;
        border: 1px solid hsl(var(--black) / .4);
        cursor: pointer;
        transition: .2s linear;
        margin-top: 0;
        &::before {
            position: absolute;
            content: "\f00c";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            color: hsl(var(--static-white)); 
            @include font-11; 
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: .2s linear;
            visibility: hidden;
            opacity: 0;
        }
        &::after {
            position: absolute;
            content: "";
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%) scale(.2);
            transform: translate(-50%, -50%) scale(.2);
            width: 10px;
            height: 10px;
            background-color: hsl(var(--main));
            border-radius: 50%;
            transition: .2s linear;
            z-index: 1;
            visibility: hidden;
            opacity: 0;
        }
        &:checked {
            background-color: hsl(var(--main)) !important;
            border-color: hsl(var(--main)) !important;
            box-shadow: none;
            &[type=checkbox] {
                background-image:none;
            }
            &::before {
                visibility: visible;
                opacity: 1;
            }
        }
    }
    .form-check-label {
        font-weight: 500;
        width: calc(100% - 18px);
        padding-left: 12px;
        cursor: pointer;
        @include font-14; 
        color: hsl(var(--heading-color)); 
        font-family: var(--poppins-font)
    }
    label {
        @include xsm-screen {
            @include font-15; 
        }
    }
    a {
        @include xsm-screen {
            @include font-15; 
        }
    }
}
.informativa-container{
    padding-left:200px;
    padding-right:200px;
}

textarea {
    height: 200px;
    width : 100%;
}
.object-fit-cover {
    object-fit: cover;
  }
.popular__item {
transition: transform 0.3s ease;
}
.popular__item:hover {
transform: translateY(-5px);
}
/* Custom Checkbox & Radio Css End */
/* ================================= Form Css End =========================== */